import React from "react"
import Layout from "../components/layout"
import pageLayout from "../pages/pages.module.css"
import background from "../Figures/Site_Header_Background.png"
import indexStyles from "./index.module.css"

export default function About() {
  return (
    <div>
      <div>
    <Layout></Layout>
        <div style={{backgroundImage: "url(" + background + ")", backgroundSize: "cover", minHeight: "200px"}} >
        <div className={indexStyles.header} style={{}}>
      <h1 style={{color: "white", whiteSpace:"nowrap"}}>Hey there!</h1>
      <h2 style={{color: "white"}}>You have a question, <br /> or a remark, suggestion, proposal, comment,...</h2>
        </div>
        </div>
    </div >
    <div className={indexStyles.top}>
      <h1>About me</h1>
      <p>
        I am an engineer by trade. If I am not working I like to jump from one hobby to another. Like to be busy in the garden and make stuff. This means that the projects here will probably be all over the place in terms of subject. At least I'll try to stay interested long enough to finish them all! :-)
      </p>
    </div>
    </div>
  )
}